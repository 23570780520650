<template>
  <div class="fill">
    <new-schedule-first-step
      v-if="state.currentStep === 1"
      @submit="firstStepSubmitHandler"
    />
    <new-schedule-second-step
      v-if="state.currentStep === 2"
      @backBtn="state.currentStep = 1"
      @submit="secondStepSubmitHandler"
    />
  </div>
</template>

<script>
  import NewScheduleFirstStep from "@/modules/agenda/views/components/NewScheduleFirstStep";
  import NewScheduleSecondStep from "@/modules/agenda/views/components/NewScheduleSecondStep";

  import { mapActions } from "vuex";

  export default {
    name: "NewSchedule",

    data: () => ({
      state: {
        currentStep: 1
      },
      formSchedule: {
        collectionRequests: [{}],
        driverId: "",
        scheduleDate: "",
        shift: "",
        observation: ""
      }
    }),

    components: {
      NewScheduleFirstStep,
      NewScheduleSecondStep
    },

    methods: {
      ...mapActions("agenda", ["addCollectionSchedule"]),
      ...mapActions(["toggleSnackbar"]),

      firstStepSubmitHandler(requests) {
        this.formSchedule.collectionRequests = requests.map(request => {
          return { id: request.id };
        });
        this.state.currentStep++;
      },

      async secondStepSubmitHandler(e) {
        try {
          const response = await this.addCollectionSchedule({
            collectionRequests: this.formSchedule.collectionRequests,
            ...e
          });

          this.toggleSnackbar({
            text:
              response?.data?.message ?? "Agendamento cadastrado com sucesso",
            type: "success"
          });

          this.$router.push({ name: "agenda" });
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      },
      mountUserPayload() {
        const payload = {};

        delete payload.userRole;
        return payload;
      },
      mountUserRoles() {
        const role = {
          id: this.formUser.userRole.roleId
        };

        if (this.userIsManagerOperation) {
          return [
            {
              role,
              crc: {
                id: this.selectedCrc.id
              }
            }
          ];
        }
        if (this.formUserCrc.crcs.length && this.state.selectedRoleCanHaveCrc) {
          return this.formUserCrc.crcs.map(crcId => ({
            role,
            crc: {
              id: crcId
            }
          }));
        }
        return [{ role }];
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/forms";
  @import "@/assets/stylesheets/components";

  @include form-register();
</style>
