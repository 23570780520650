<template>
  <v-dialog v-model="state" :persistent="true" width="536">
    <v-card class="modal">
      <v-card-title class="modal-title">Selecionar Motorista</v-card-title>

      <div class="px-5">
        <v-text-field
          solo
          flat
          full-width
          background-color="white-1"
          color="secondary-color-1"
          placeholder="Buscar motorista"
          prepend-inner-icon="mdi-magnify"
          @input="debounceInput"
        ></v-text-field>
      </div>
      <v-divider></v-divider>

      <validation-observer>
        <v-form @submit.prevent="submit" slot-scope="{ invalid }">
          <div v-if="drivers.length" class="data-container px-5">
            <validation-provider name="radio" :rules="{ required: true }">
              <v-radio-group
                v-model.lazy="driverSelected"
                :outlined="true"
                class="mt-2"
                :color="$vuetify.theme.themes.light['secondary-color-1']"
                name="drivers"
                id="drivers"
                :required="true"
              >
                <v-radio
                  v-for="item in drivers"
                  color="primary-color-1"
                  :key="item.id"
                  :label="item.firstName"
                  :value="item"
                >
                  <template v-slot:label>
                    <div class="ml-2 d-flex align-center">
                      <user-photo
                        v-model="item.photoHash"
                        border="thin"
                        size="sm"
                      />
                      <div class="d-flex flex-wrap">
                        <span class="font-weight-medium black-1--text mx-2"
                          >{{ item.firstName }} {{ item.surname }}</span
                        >
                        -
                        <span class="ml-2 black-1--text">{{
                          item.cpf | VMask(masks.cpf)
                        }}</span>
                      </div>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </validation-provider>

            <infinite-loading spinner="waveDots" @infinite="loadMore">
              <div slot="no-more"></div>
              <div slot="no-results"></div>
            </infinite-loading>
          </div>

          <div v-if="!drivers.length" class="mt-5">
            <data-not-found
              :title="dataNotFound"
              :imgSrc="require('@/assets/partner.png')"
              :btnAction="dataNotFound.btnAction"
            />
          </div>

          <v-divider></v-divider>

          <v-card-actions class="d-flex flex-wrap justify-space-between">
            <v-btn
              class="mt-1 black-3--text cancel-button"
              color="#FFF"
              :block="$isMobile"
              @click="backBtn"
              >Voltar</v-btn
            >
            <v-btn
              type="submit"
              :disabled="invalid || !drivers.length"
              color="secondary-color-1"
              :block="$isMobile"
              class="ma-0 mt-1 white--text next-step-button"
              >Selecionar motorista</v-btn
            >
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapActions } from "vuex";
  import { debounce } from "lodash";
  import image from "@/assets/Avatar_.png";
  import { ValidationObserver } from "vee-validate";
  import InfiniteLoading from "vue-infinite-loading";
  import TableHandler from "@/modules/core/mixins/tableHandler";
  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import UserPhoto from "@/modules/account/user/views/components/UserPhoto";
  import DataNotFound from "@/modules/core/views/components/DataNotFound";
  import { profiles } from "@/modules/core/enums/profiles.enum";

  import { mapGetters } from "vuex";

  export default {
    name: "SelectDriverDialog",

    props: {
      backBtn: {
        type: Function,
        required: true
      },
      state: {
        type: Boolean,
        required: true
      },
      driverSelectedProp: {
        type: Number
      }
    },

    components: {
      ValidationObserver,
      InfiniteLoading,
      DataNotFound,
      UserPhoto
    },

    mixins: [TableHandler, MasksMixin],

    data: () => ({
      image,
      driverSelected: "",
      drivers: [],
      partner: "",
      searchTerm: "",
      filterPristine: true,
      page: 1,
      limit: 10
    }),

    async mounted() {
      this.initFilter("name", "");
      this.initFilter("roles", profiles.DRIVER.id);
      await this.loadMore();
      if (this.driverSelectedProp) {
        this.driverSelected = this.drivers.find(
          el => el.id === this.driverSelectedProp
        );
      }
    },

    computed: {
      ...mapGetters({ loggedUserCrc: "user/getSelectedUserCRC" }),
      dataNotFound() {
        return this.filterPristine
          ? "Busque por um motorista"
          : "Motorista não encontrado";
      }
    },

    methods: {
      ...mapActions("crc", ["getUsersFromCRC"]),
      ...mapActions(["toggleSnackbar"]),

      async fetchDrivers() {
        try {
          const { data } = await this.getUsersFromCRC({
            id: this.loggedUserCrc.id,
            data: this.filters
          });
          return data;
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      },

      async loadMore($state) {
        const data = await this.fetchDrivers();

        if (data) {
          this.drivers = this.formatDrivers(data);
          if ($state) {
            $state.complete();
          }
        }
      },

      formatDrivers(drivers) {
        return drivers.map(driver => ({
          ...driver,
          photoHash: driver.photo ? driver.photo.hash : null
        }));
      },

      debounceInput: debounce(async function(value) {
        this.filterPristine = false;
        this.filters.name = value;

        this.drivers = await this.fetchDrivers();
      }, 800),

      submit() {
        this.$emit("submit", this.driverSelected);
      },

      mountPayload() {
        return {
          description: this.searchTerm,
          page: this.page,
          limit: this.limit
        };
      },

      getImage(hash) {
        return `${process.env.VUE_APP_API_BASE_URL}/file/${hash}`;
      },

      updateData() {
        this.fetchDrivers();
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .data-container {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (max-width: 460px) {
      height: 260px;
    }
  }

  .user-profile {
    height: 32px;
    border-radius: 100%;
  }
</style>
