<template>
  <v-container class="content-page-half">
    <title-header subtitle="Passo 2 de 2" />

    <v-card class="mt-3 pt-7">
      <validation-observer>
        <v-form @submit.prevent="submit()" slot-scope="{ invalid }">
          <div class="px-5">
            <h3 class="mb-n5">Selecionar Motorista</h3>
            <div
              @click="seletectDriver()"
              class="add-new-driver d-flex flex-column align-center mb-6"
            >
              <span v-if="!labelSeletectedDriver()" class="font-weight-medium"
                >Selecione um motorista</span
              >
              <span v-if="labelSeletectedDriver()" class="font-weight-medium">
                {{ labelSeletectedDriver() }}
                <v-icon
                  class="font-weight-medium black-1--text ml-4 mt-n1"
                  size="22"
                  >mdi-close</v-icon
                >
              </span>
            </div>

            <h3 class="mb-4">Selecionar data de coleta</h3>
            <date-field
              v-model="form.scheduleDate"
              label="Data da coleta"
              :isRequired="true"
            />
            <p
              v-if="form.scheduleDate"
              class="mt-n3 font-weight-medium text-center primary-color-1--text"
            >
              {{ getDate(form.scheduleDate) }}
            </p>

            <h3 class="mb-2">Turno</h3>
            <field
              v-model="form.shift"
              type="select"
              :items="Object.keys(shifts)"
              label="Turno"
              name="turno"
              :isRequired="true"
            />

            <h3>Observação</h3>
            <v-row>
              <v-col cols="12">
                <field
                  v-model="form.observation"
                  type="textarea"
                  label="Descrição"
                  name="description"
                  max="250"
                  :isRequired="true"
                />
              </v-col>
            </v-row>
          </div>

          <v-divider></v-divider>

          <v-card-actions class="d-flex flex-wrap justify-space-between">
            <v-btn
              @click="$emit('backBtn')"
              color="#FFF"
              class="mt-1 cancel-button black-3--text"
              :block="$isMobile"
              >Cancelar</v-btn
            >
            <v-btn
              type="submit"
              color="secondary-color-1"
              :disabled="invalid || !selectedDriver"
              :block="$isMobile"
              class="ma-0 mt-1 next-step-button white--text"
              >Criar</v-btn
            >
          </v-card-actions>
        </v-form>
      </validation-observer>
      <!-- Driver -->
      <select-driver-dialog
        :backBtn="() => (showSelectDriverDialog = false)"
        :state="showSelectDriverDialog"
        @submit="seletectDriver"
      />
    </v-card>
  </v-container>
</template>
<script>
  import Field from "@/modules/core/views/components/Field";
  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import DateField from "@/modules/core/views/components/DateField";
  import { shiftTypes } from "@/modules/agenda/enums/shiftTypes.enum";
  import SelectDriverDialog from "@/modules/agenda/views/components/SelectDriverDialog";
  import TitleHeader from "@/modules/core/views/components/TitleHeader";

  import { parseISO } from "date-fns";
  import { ValidationObserver } from "vee-validate";

  export default {
    name: "NewScheduleSecondStep",

    mixins: [MasksMixin],

    components: {
      Field,
      DateField,
      ValidationObserver,
      SelectDriverDialog,
      TitleHeader
    },

    data: () => ({
      form: {},
      showSelectDriverDialog: false,
      selectedDriver: ""
    }),

    computed: {
      shifts() {
        return shiftTypes;
      }
    },

    methods: {
      submit() {
        this.$emit("submit", this.form);
      },

      getDate(date) {
        const dateParseIso = parseISO(date);
        return this.parseDate(dateParseIso, "dd 'de' MMMM 'de' yyyy");
      },

      seletectDriver(e) {
        if (e) {
          this.showSelectDriverDialog = false;
          this.selectedDriver = e;
          this.form.driverId = e.id;
        } else {
          this.form.driverId = "";
          this.showSelectDriverDialog = true;
          this.selectedDriver = "";
        }
      },

      labelSeletectedDriver() {
        if (this.selectedDriver) {
          return `${this.selectedDriver.firstName} ${this.selectedDriver.surname}`;
        }
        return "";
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "~@/assets/stylesheets/styleguide";

  .add-new-driver {
    background-color: #f3f6f9;
    margin-top: 2em;
    padding: 1.2em;
    border: 1px dashed $primary-color-1;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
</style>
