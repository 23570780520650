<template>
  <v-container>
    <title-header subtitle="Passo 1 de 2" />

    <v-card>
      <div class="table-page d-flex flex-column justify-space-between">
        <div>
          <!--filters -->
          <template>
            <div class="row px-5 d-flex justify-end">
              <v-col cols="12" sm="6">
                <h3 class="mt-4 black-4--text font-weight-medium">
                  Selecionar solicitações
                </h3>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  @input="setFilterDebounced('name', $event)"
                  solo
                  flat
                  full-width
                  color="secondary-color-1"
                  background-color="white-1"
                  placeholder="Buscar solicitação"
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>
            </div>
            <v-divider></v-divider>
          </template>
          <template v-if="!requests.length">
            <div class="mt-5">
              <data-not-found
                v-if="!filterPristine"
                title="Solicitação não encontrada."
                :imgSrc="require('@/assets/collection-schedule.png')"
              />
              <data-not-found
                v-if="filterPristine"
                title="Você ainda não cadastrou nenhuma solicitação, deseja cadastrar?"
                btnLabel="Cadastrar a primeira solicitação"
                :btnAction="goToAddRequest"
                :imgSrc="require('@/assets/collection-schedule.png')"
              />
            </div>
          </template>
          <template v-if="requests.length">
            <v-data-table
              :headers="headers"
              :items="requests"
              :items-per-page="tableInfo.itemsPerPage"
              :single-select="singleSelect"
              v-model="selected"
              show-select
              hide-default-footer
            ></v-data-table>
          </template>
        </div>
        <v-card-actions v-if="requests.length">
          <pagination
            :currentPage="tableInfo.currentPage"
            :totalPages="tableInfo.totalPages"
            :itemsPerPage="tableInfo.itemsPerPage"
            @currentPageChanged="handleCurrentPageChange($event)"
            @itemsPerPageChanged="handleItemsPerPageChange($event)"
          />
        </v-card-actions>
      </div>
    </v-card>

    <v-row justify="end">
      <v-col cols="12" sm="5" md="4" lg="2">
        <v-btn
          depressed
          class="cancel-button black-3--text"
          block
          @click="$router.back()"
          >Cancelar</v-btn
        >
      </v-col>
      <v-col cols="12" sm="5" md="4" lg="2">
        <v-btn
          color="secondary-color-1"
          class="next-step-button white--text"
          block
          :disabled="!selected.length"
          @click="nextStep()"
          >Próximo</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import TableHandler from "@/modules/core/mixins/tableHandler";
  import DataNotFound from "@/modules/core/views/components/DataNotFound";
  import Pagination from "@/modules/core/views/components/Pagination";
  import TitleHeader from "@/modules/core/views/components/TitleHeader";

  import { mapActions } from "vuex";

  export default {
    name: "NewScheduleFirstStep",

    components: {
      DataNotFound,
      Pagination,
      TitleHeader
    },

    mixins: [MasksMixin, TableHandler],

    data: () => ({
      headers: [
        { text: "Data/Hora", value: "createdAt" },
        { text: "Tipo", value: "description" },
        { text: "Doador/Parceiro", value: "sourceName" },
        { text: "Telefone", value: "phone" },
        { text: "Cidade", value: "address.city" },
        { text: "Status", value: "situation" }
      ],
      requests: [],
      singleSelect: false,
      selected: []
    }),
    created() {
      this.initFilter("name", "");
    },
    mounted() {
      this.fetchRequests();
    },

    computed: {
      showFilters() {
        if (this.requests.length) {
          return true;
        }
        return !this.requests.length && !this.filterPristine;
      }
    },
    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("agenda", ["getCollection"]),

      goToAddRequest() {
        this.$router.push({ name: "newRequest" });
      },

      async fetchRequests() {
        try {
          const payload = this.getPayloadData();
          const { data, meta } = await this.getCollection({
            ...payload,
            situations: "1,4"
          });
          this.requests = this.formatRequestsData(data);
          this.setTableInfo(meta);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      formatRequestsData(requests) {
        return requests.map(data => {
          const phone = data.phones.length
            ? this.parsePhone(data.phones[0])
            : "não informado";

          return {
            id: data.id,
            createdAt: this.parseDate(data.createdAt, "dd/MM/yyyy - HH:mm"),
            description: data.type.description,
            sourceName: data.sourceName,
            phone,
            situation: data.situation.description,
            address: data.address
          };
        });
      },

      nextStep() {
        this.$emit("submit", this.selected);
      },

      updateData() {
        this.fetchRequests();
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/buttons";

  .btn-actions {
    width: fit-content;
    margin-left: auto;
  }
</style>
